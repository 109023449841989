<template>
  <v-sheet elevation="2" class="pa-5" overflow="scroll">
    <v-form v-model="valid" ref="form" @submit.prevent="saveSupplier">
      <v-container fluid>
        <!--Header-->
        <v-row class="mb-5">
          <v-col md="12" sm="12">
            <h2>Add New Supplier</h2>
          </v-col>
        </v-row>
        <!--Form-->
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <h4>Company Details</h4>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="6" sm="6">
            <!--Account Type-->
            <v-text-field
              v-model="supplier_name"
              counter="50"
              dense
              outlined
              label="Company Name"
              required
            >
            </v-text-field>
          </v-col>
          <!--Availability-->
          <v-col cols="12" sm="6" md="6">
            <v-select
              v-model="supplier_status"
              :items="['Available', 'Unavailable']"
              label="Availability status"
              required
              outlined
              dense
              color="#3d2cdd"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              v-model="supplier_VAT_number"
              counter="10"
              label="VAT Number"
              outlined
              dense
              color="#3d2cdd"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              v-model="supplier_registration_number"
              label="Registration Number"
              outlined
              dense
              color="#3d2cdd"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!--Contact details-->
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <h4>Primary Contact Person</h4>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-text-field
              v-model="supplier_contact_person_name"
              label="Full Name"
              outlined
              dense
              color="#3d2cdd"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              v-model="supplier_contact_person_cell"
              label="Cell Number"
              append-icon="mdi-phone"
              outlined
              dense
              color="#3d2cdd"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              v-model="supplier_contact_person_email"
              label="Email"
              append-icon="mdi-email"
              outlined
              dense
              color="#3d2cdd"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!--Address-->
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <h4>Address</h4>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              v-model="supplier_addr_1"
              label="Address Line 1"
              append-icon="mdi-map-marker"
              outlined
              dense
              color="#3d2cdd"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              v-model="supplier_addr_2"
              label="Address Line 2"
              outlined
              dense
              color="#3d2cdd"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              v-model="supplier_city"
              label="City"
              outlined
              dense
              color="#3d2cdd"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              v-model="supplier_zip"
              label="Zip Code"
              outlined
              dense
              color="#3d2cdd"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-divider class="my-3"></v-divider>
        <v-row>
          <v-col>
            <v-card-actions>
              <v-spacer></v-spacer>
              <!--New-->
              <span>
                <v-btn text left class="px-3" @click="passDialogChange($event)"
                  >Cancel</v-btn
                >
                <v-btn
                  right
                  color="#33cc99"
                  class="white--text px-3"
                  :disabled="!valid"
                  :loading="loading === true"
                  @click="
                    saveSupplier();
                    passSupplierDetails();
                  "
                  >Submit
                  <v-icon right>mdi-check</v-icon>
                </v-btn>
              </span>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-sheet>
</template>
<script>
import db from "../../../components/firebaseInit";
import firebase from "firebase";
import { showSnackbar } from "../../../globalActions/index";
export default {
  name: "NewSupplierModal",
  data() {
    return {
      valid: false,
      loading: false,
      supplier_id: null,
      supplier_name: null,
      supplier_status: "Available",
      supplier_VAT_number: null,
      supplier_registration_number: null,
      supplier_contact_person_name: null,
      supplier_contact_person_cell: null,
      supplier_contact_person_email: null,
      supplier_addr_1: null,
      supplier_addr_2: null,
      supplier_city: null,
      supplier_zip: null,
      supplier_date_created: null,
      supplier_timestamp: null,
      supplier_created_by_id: null,
      supplier_created_by_name: null,
    };
  },
  created() {
    //Captures user that created the sales order
    this.supplier_created_by_id = firebase.auth().currentUser.uid;
    db.collection("users")
      .where("uid", "==", this.supplier_created_by_id)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.supplier_created_by_name = doc.data().displayName;
        });
      });
  },
  methods: {
    passDialogChange() {
      this.$emit("closeDialog", false);
    },
    saveSupplier() {
      //Form validation --> ensures required fields aren't blank before continuing further
      if (this.$refs.form.validate()) {
        this.loading = true;
        var newDocRef = db.collection("suppliers").doc();
        newDocRef
          .set({
            supplier_id: newDocRef.id,
            supplier_name: this.supplier_name,
            supplier_status: this.supplier_status,
            supplier_VAT_number: this.supplier_VAT_number,
            supplier_registration_number: this.supplier_registration_number,
            supplier_contact_person_name: this.supplier_contact_person_name,
            supplier_contact_person_cell: this.supplier_contact_person_cell,
            supplier_contact_person_email: this.supplier_contact_person_email,
            supplier_addr_1: this.supplier_addr_1,
            supplier_addr_2: this.supplier_addr_2,
            supplier_city: this.supplier_city,
            supplier_zip: this.supplier_zip,
            supplier_date_created: new Date().toISOString().slice(0, 10),
            supplier_timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            supplier_created_by_id: this.supplier_created_by_id,
            supplier_created_by_name: this.supplier_created_by_name,
          })
          //Reroute to customers list after database is updated
          .then(() => {
            this.loading = false;
            showSnackbar("New supplier added successfully");
            this.passDialogChange();
            //Reset form fields
            Object.assign(this.$data, this.$options.data.call(this));
          });
      }
    },
    passSupplierDetails() {
      this.$emit("passSupplierDetails", this.supplier_name);
    },
  },
};
</script>
